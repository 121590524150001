.ag-theme-alpine {
    --ag-font-size: 11px;
}

.ag-theme-alpine ::-webkit-scrollbar:horizontal {
    height: 10px;
}

.ag-theme-alpine ::-webkit-scrollbar:vertical {
    width: 10px;
}

.ag-cell {
    border-left: 0.4px solid #B5B5C3;
}